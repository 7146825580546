/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
body , * {
    margin: 0px;
    padding: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html, body { height: 100vh; background: #dde4f3a6; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
     }

.mat-custom {
    // background-color: green;
    background: linear-gradient(to right, #eea849, #f46b45);
    color: #fff;
}
.mat-custom-cancel {
    background: linear-gradient(to right, #212529, #343a40);
    color: #fff;
}
.main-container {
    background: #dde4f3a6;  
}
